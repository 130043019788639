<template>
  <div
    class="overflow-hidden w-[36px] shrink-0 bg-primary-lighter relative flex items-center justify-center"
  >
    <div class="w-[28px] h-[230px] absolute">
      <img
        v-if="$siteIdent.startsWith('minilu')"
        src="@/assets/images/minilu/minilu-campaign-minibadge.svg"
        alt="miniBadge"
        class="relative top-[3px]"
      />
      <img
        v-else
        src="@/assets/images/vdv/vdv-campaign-minibadge.svg"
        alt="miniBadge"
        class="relative top-[3px]"
      />
    </div>
  </div>
</template>
<script setup lang="ts"></script>
